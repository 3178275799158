import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import {
  ButtonCustomLink,
  ImportantMessage,
  Loading,
  RetryErrorMessage,
} from "@/components";
import { addressDataQuery } from "@/services/queries";
import type { UserShippingDataInput } from "@/services/types";

const pickUpFormSchema = z.object({
  selected_pickup_district_name: z.string({
    required_error:
      "⚠️ Necesitamos la comuna para saber que puntos de retiro mostrarte",
  }),
  selected_pickup_point_name: z.string({
    required_error:
      "⚠️ Necesitamos el punto de retiro para saber a donde enviar tus cajas",
  }),
});

export type PickUpFormSchema = z.infer<typeof pickUpFormSchema>;
export interface PickUpDeliveryData extends PickUpFormSchema {
  selected_shipping_type_humanized: string;
}

type PickUpStoreFormProps = {
  initialData: UserShippingDataInput;
  onSubmit: (data: PickUpDeliveryData, shippingCost: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
  onGoBack?: () => void;
};

const PickUpStoreForm = ({
  onSubmit,
  initialData,
  isLoading,
  isError,
  error,
  onGoBack,
}: PickUpStoreFormProps) => {
  const [shippingCost, setShippingCost] = useState<string>();
  const [selectedDistrict, setSelectedDistrict] = useState(
    initialData.selected_pickup_district_name,
  );
  const [selectedPickUpPointName, setSelectedPickUpPointName] = useState(
    initialData.selected_pickup_point_name,
  );

  const {
    data: response,
    isLoading: responseIsLoading,
    isError: responseIsError,
    error: responseError,
    refetch: responseRefetch,
  } = addressDataQuery();
  const data = response?.data;

  const { selected_pickup_district_name, selected_pickup_point_name } =
    initialData;

  const form = useForm<PickUpFormSchema>({
    resolver: zodResolver(pickUpFormSchema),
    defaultValues: {
      selected_pickup_district_name:
        selected_pickup_district_name !== ""
          ? selected_pickup_district_name
          : undefined,
      selected_pickup_point_name:
        selected_pickup_point_name !== ""
          ? selected_pickup_point_name
          : undefined,
    },
  });

  function handleSubmit(values: PickUpFormSchema) {
    onSubmit(
      {
        ...values,
        selected_shipping_type_humanized: "Retiro en tiendas",
      },
      shippingCost || "",
    );
  }

  if (responseIsLoading) {
    return <Loading message="🔍 Buscando puntos de retiro..." />;
  }

  if (responseIsError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de los puntos de retiro."}
        message={responseError.message}
        retry={responseRefetch}
        error={responseError}
      />
    );
  }

  if (data) {
    const { pickup_point_district_names, pickup_points } = data;

    const pickupPointsFromSelectedDistrict = pickup_points.filter(
      (p) => p["district_name"] === selectedDistrict,
    );

    const selectedPickUpPoint = pickupPointsFromSelectedDistrict.find(
      (p) => p.name === selectedPickUpPointName,
    );

    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="selected_pickup_district_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Comuna</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    setSelectedDistrict(value);
                  }}
                  defaultValue={field.value}
                  disabled={isLoading}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecciona una comuna" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {pickup_point_district_names.map((districtName) => (
                      <SelectItem key={districtName} value={districtName}>
                        {districtName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="selected_pickup_point_name"
            render={({ field }) => {
              const selectedPickupPoint = data.pickup_points.find(
                (p) => p.name === field.value,
              );
              if (selectedPickUpPoint) {
                setShippingCost(String(selectedPickUpPoint.pickup_price));
              }
              return (
                <FormItem>
                  <FormLabel>Punto de retiro</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      setSelectedPickUpPointName(value);
                    }}
                    defaultValue={field.value}
                    disabled={selectedDistrict === undefined || isLoading}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecciona un punto de retiro" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {/* TODO: replace name to id when there's a different endpoint to get the pick up points */}
                      {pickupPointsFromSelectedDistrict.map((p) => (
                        <SelectItem key={p.name} value={p.name}>
                          {p.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {selectedPickupPoint && (
                    <FormDescription>
                      Costo de envío: {selectedPickupPoint.pickup_price}
                    </FormDescription>
                  )}
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {selectedPickUpPoint && (
            <div className="mt-4">
              <ImportantMessage
                title={<p className="text-base">📍{selectedPickUpPointName}</p>}
                message={
                  <div className="w-full">
                    <div className="mb-2">
                      <a
                        className="underline decoration-orange-500 text-primary font-semibold"
                        href={`https://maps.google.com/maps?hl=es&q=${selectedPickUpPoint?.address} - ${selectedDistrict}`}
                      >
                        {selectedPickUpPoint?.address} - {selectedDistrict}
                      </a>
                      <p>
                        Dias:{" "}
                        <strong className="text-primary">
                          {selectedPickUpPoint?.humanized_shipping_days}
                        </strong>
                      </p>
                      <p>
                        Horario de atención:{" "}
                        <strong className="text-primary">
                          {selectedPickUpPoint?.humanized_shipping_days} |{" "}
                          {selectedPickUpPoint?.opening_hours}
                        </strong>
                      </p>
                    </div>
                    <iframe
                      width="100%"
                      height="300"
                      src={`https://maps.google.com/maps?width=400&height=400&hl=es&q=${selectedPickUpPoint?.address} - ${selectedPickUpPoint.district_name}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                    ></iframe>
                  </div>
                }
              />
            </div>
          )}
          {isError && (
            <FormMessage className="text-right">
              {error?.message || "Hubo un error inesperado"}
            </FormMessage>
          )}
          <div className="flex justify-between pb-4 gap-4">
            {onGoBack ? (
              <Button
                onClick={onGoBack}
                type="button"
                variant="outline"
                className="w-full sm:w-fit"
                disabled={isLoading}
              >
                Volver
              </Button>
            ) : (
              <ButtonCustomLink to="/user/home" variant="outline">
                Volver
              </ButtonCustomLink>
            )}
            {initialData.selected_shipping_type !== "PICKUP" ? (
              <Button
                type="submit"
                className="w-full sm:w-fit"
                disabled={isLoading}
              >
                {isLoading ? "Cambiando" : "Usar este método"}
              </Button>
            ) : (
              <Button
                type="submit"
                className="w-full sm:w-fit"
                disabled={isLoading}
              >
                {isLoading ? "Guardando" : "Guardar"}
              </Button>
            )}
          </div>
        </form>
      </Form>
    );
  }
};

export default PickUpStoreForm;
