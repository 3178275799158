import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { ImportantMessage } from "@/components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { deleteMutation } from "@/services/queries";
import { useEffect } from "react";
import { logout } from "@/firebase";

const cancelSuscriptionSchema = z.object({
  user_action: z.string(),
  termination_comments: z.string().optional(),
  termination_reason: z.string({
    required_error:
      "⚠️ Por favor selecciona una opción. Nos ayudaría a mejorar.",
  }),
});

export type CancelSuscriptionSchema = z.infer<typeof cancelSuscriptionSchema>;

const REASONS_TO_LEAVE = [
  {
    reason: "Me sobran productos",
    advice: (
      <ImportantMessage
        title="💡 ¿Tu caja es muy grande?"
        message={
          <Link to="/user/subscription/edit" className="underline">
            Podrias cambiar el tamaño de tu caja o cambiar de frecuencia de
            envio. ¿Quieres intentarlo?
          </Link>
        }
      />
    ),
  },
  {
    reason: "Prefiero comprar a granel en Maifud",
  },
  {
    reason: "Cambio de ciudad o casa",
  },
  {
    reason: "La variedad de productos no me acomoda",
    advice: (
      <ImportantMessage
        title="💡 ¿La variedad de productos no te acomoda?"
        message={
          <Link to="/user/home" className="underline">
            Cada semana que recibes tu caja puedes personalizarla y quitar lo
            que no te gusta (o que aún te queda en el refri)
          </Link>
        }
      />
    ),
  },
  {
    reason: "Problemas con la app",
  },
  {
    reason: "Problemas con el despacho / reparto",
  },
  {
    reason: "Problemas con el pedido (no llegó lo que pedí)",
    advice: (
      <ImportantMessage
        title="💡 ¿Problemas con el estado de los produtos?"
        message={
          <p>
            Si te pasó esto,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/+56971295665"
              className="underline"
            >
              escríbenos a nuestro whatsapp de soporte
            </a>{" "}
            para buscar una solución. Queremos que tus pedidos te hagan tan
            feliz como a nosotros.
          </p>
        }
      />
    ),
  },
  {
    reason:
      "No me gustó la apariencia (color, cicatrices, tamaño) de los productos",
  },
  {
    reason: "Recibí productos en mal estado",
    advice: (
      <ImportantMessage
        title="💡 ¿Problemas con el estado de los produtos?"
        message={
          <p>
            Si te pasó esto,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/+56971295665"
              className="underline"
            >
              escríbenos a nuestro whatsapp de soporte
            </a>{" "}
            para buscar una solución. Queremos que tus frutas y verduras te
            hagan tan feliz como a nosotros.
          </p>
        }
      />
    ),
  },
  {
    reason: "Me parece alto el precio del despacho",
  },
  {
    reason: "Me parece alto el precio de la caja",
  },
  {
    reason: "Estaba probando el servicio",
  },
];

type DeleteAccountProps = {
  open: boolean;
  onClose: () => void;
};

const DeleteAccount = ({ open, onClose }: DeleteAccountProps) => {
  const navigate = useNavigate();
  const form = useForm<CancelSuscriptionSchema>({
    resolver: zodResolver(cancelSuscriptionSchema),
    defaultValues: {
      user_action: "TERMINATE",
    },
  });

  const mutation = deleteMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      logout().finally(() => {
        navigate({
          to: "/",
        });
      });
    }
  }, [mutation.isSuccess]);

  const handleSubmit = (values: CancelSuscriptionSchema) => {
    mutation.mutate(values);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <Form {...form}>
          <form
            className="space-y-2"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <DialogHeader>
              <DialogTitle>Lamentamos no cumplir tus expectativas</DialogTitle>
              <DialogDescription>
                Al confirmar, tu suscripción quedara desactivada y no haremos
                ningún cobro adicional.
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-4">
              <div className="w-20 mx-auto">
                <img src="/sad_box.png" alt="Caja triste" className="w-full" />
              </div>
              <FormField
                control={form.control}
                name="termination_reason"
                render={({ field }) => {
                  const advice = REASONS_TO_LEAVE.find(
                    (r) => r.reason === field.value,
                  )?.advice;
                  return (
                    <>
                      <FormItem>
                        <FormLabel htmlFor="termination_reason">
                          ¿Qué motivó tu cancelación?
                        </FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={
                            field.value
                              ? field.value.toLowerCase()
                              : field.value
                          }
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {REASONS_TO_LEAVE.map((reason) => (
                              <SelectItem
                                key={reason.reason}
                                value={reason.reason}
                              >
                                {reason.reason}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                      {advice ? <div className="my-2">{advice}</div> : null}
                    </>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="termination_comments"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Información adicional</FormLabel>
                    <FormControl>
                      <Textarea
                        onChange={field.onChange}
                        value={field.value}
                        className="text-base md:text-sm"
                      />
                    </FormControl>
                    <FormDescription>Déjanos tu comentario</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter className="my-4 flex flex-col lg:justify-end lg:flex-row gap-4">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={onClose}
                  disabled={mutation.isPending}
                >
                  Cerrar
                </Button>
              </DialogClose>
              <Button
                type="submit"
                variant="destructive"
                disabled={mutation.isPending}
                isLoading={mutation.isPending}
              >
                {mutation.isPending ? "Guardando..." : "Desactivar suscripción"}
              </Button>
            </DialogFooter>

            {mutation.isError && (
              <FormMessage className="text-right">
                {mutation.error.message}
              </FormMessage>
            )}
            {mutation.isSuccess && (
              <FormMessage className="text-right text-primary">
                Tu suscripción se ha desactivado correctamente.
              </FormMessage>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAccount;
