import { Link } from "@tanstack/react-router";
import SVGSeparator from "./SVGSeparator";
import { useUser } from "@/firebase";
import { Skeleton } from "@/components/ui/skeleton";
import boxBig from "@/assets/photos/original/big.webp";
import { cn } from "@/lib/utils";

const HeroImage = () => {
  const { user, isLoading } = useUser();

  const renderCallToAction = () => {
    if (isLoading) {
      return <Skeleton className="w-[250px] h-[60px] bg-orange-500/30" />;
    }
    if (user?.email) {
      return (
        <button
          type="button"
          className="bg-primary text-primary-foreground rounded-md text-lg md:text-xl"
        >
          <Link to="/user/home" className="py-4 px-6 font-raleway block">
            Ir a tu suscripción
          </Link>
        </button>
      );
    } else {
      return (
        <>
          <div className="flex flex-col items-center lg:flex-row gap-4">
            <button
              type="button"
              className="bg-primary text-primary-foreground rounded-md text-lg md:text-xl hover:shadow-lg"
            >
              <Link
                to="/register/subscriptions"
                className="py-4 px-6 font-raleway block"
              >
                Quiero mi suscripción
              </Link>
            </button>
          </div>
          <a
            href="https://tienda.maifud.cl/"
            target="_blank"
            className=" text-white bg-[#7B7D2A] text-center py-2 px-6  rounded-md flex items-center justify-center text-lg hover:shadow-lg"
          >
            Comprar a granel
          </a>
        </>
      );
    }
  };

  return (
    <>
      <div
        className={cn(
          "relative lg:pb-8 bg-[#f6e9dd]",
          user?.email ? "pt-[80px]" : "pt-[130px]",
        )}
      >
        <div className="pt-6 lg:pt-0 px-30 flex flex-wrap flex-col lg:flex-row items-center max-w-7xl	mx-auto relative lg:h-[320px]">
          {/* <!--Left Col--> */}
          <div className="flex flex-col w-full relative z-10 justify-center items-center text-center pb-6 md:w-2/4 lg:text-left lg:items-start lg:pl-16">
            <h1 className="mt-2 mb-4 lg:my-4 text-3xl lg:text-4xl font-bold leading-tight px-2 lg:px-0">
              Ahorra en tus frutas y verduras evitando el desperdicio de
              alimentos.
            </h1>
            <p className="text-lg lg:text-2xl mt-2 mb-6">
              Personaliza el contenido de tu caja y recibe directo en tu puerta
            </p>
            <div className="w-full flex flex-col space-y-4 items-center justify-center lg:w-fit lg:items-start lg:justify-start">
              {renderCallToAction()}
            </div>
          </div>
          {/* <!--Right Col--> */}
          <div className="w-full md:w-2/4 pt-6 lg:py-6 lg:flex lg:justify-end">
            <img
              className="w-full z-30"
              src={boxBig}
              alt="Caja de maifud llena de frutas y verduras"
            />
          </div>
        </div>
      </div>
      <SVGSeparator />
    </>
  );
};
export default HeroImage;
