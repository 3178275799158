import Summary from "./Summary";
import { userResumeCheckoutQuery } from "@/services/queries";
import { useEffect, useState } from "react";
import { Loading } from "@/components";
import Subscriptions from "./Subscriptions";
import DeliveryDate from "./DeliveryDate";
import Frecuency from "./Frecuency";
import ShippingMethod from "./ShippingMethod";
import { ShippingType } from "@/services/types";
import { motion, AnimatePresence } from "framer-motion";
import { useUser, logout } from "@/firebase";
import { useNavigate } from "@tanstack/react-router";
import { XCircle } from "lucide-react";
import ReactGA from "react-ga4";

type SubscriptionIncompleteProps = {
  uid: string;
  hasError?: string;
};

export type Step =
  | "SUMMARY"
  | "SUBSCRIPTION"
  | "DELIVERY_DATE"
  | "FRECUENCY"
  | "SHIPPING_METHOD";

const SubscriptionIncomplete = ({
  uid,
  hasError,
}: SubscriptionIncompleteProps) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [step, setStep] = useState<Step>("SUMMARY");
  const [showNotLoggedInMessage, setShowNotLoggedInMessage] = useState(false);

  // Update URL when step changes
  const handleStepChange = (newStep: Step) => {
    ReactGA.event({
      category: "Subscription Incomplete",
      action: "Step Changed",
      label: newStep,
    });
    setStep(newStep);
  };

  const handleLogout = () => {
    logout().finally(() => {
      navigate({
        to: "/",
      });
    });
  };

  const {
    data: resumeCheckoutResponse,
    isLoading: resumeCheckoutIsLoading,
    isFetching: resumeCheckoutIsFetching,
  } = userResumeCheckoutQuery({ uid });

  const resumeCheckoutData = resumeCheckoutResponse?.data;
  const userIsLoggedIn = !!user;

  // Decrease width of container to have a better visualization in desktop
  useEffect(() => {
    document.querySelector("#page-layout")?.classList.remove("md:w-9/12");
    document.querySelector("#page-layout")?.classList.add("md:w-1/2");
  }, []);

  // Show not logged in message if user is not logged in
  useEffect(() => {
    setShowNotLoggedInMessage(!userIsLoggedIn);
  }, [userIsLoggedIn]);

  if (resumeCheckoutIsLoading)
    return <Loading message="🔍 Buscando tu información..." />;

  if (resumeCheckoutData === undefined) return null;

  if (resumeCheckoutData.has_subscription) {
    navigate({ to: "/user/home" });
    return null;
  }

  const pageVariants = {
    initial: {
      x: step === "SUMMARY" ? -20 : 20,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      scale: 1,
    },
    exit: {
      x: step === "SUMMARY" ? -20 : 20,
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    duration: 0.2,
  };

  const renderStep = () => {
    switch (step) {
      case "SUMMARY":
        return (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            transition={pageTransition}
          >
            <h4 className="text-lg font-semibold">
              Hola {resumeCheckoutData.user_name},{" "}
              {user && (
                <span
                  className="text-xs underline font-normal cursor-pointer"
                  onClick={handleLogout}
                >
                  Cerrar sesión
                </span>
              )}
            </h4>
            <p className="text-sm">
              Estás a un paso de completar tu suscripción de Maifud.
              {}
            </p>
            <AnimatePresence>
              {showNotLoggedInMessage && (
                <motion.div
                  className="space-y-2 border mt-4 p-4 rounded-md bg-primary/10 relative"
                  initial={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <button
                    type="button"
                    className="absolute top-2 right-2"
                    onClick={() => setShowNotLoggedInMessage(false)}
                  >
                    <XCircle className="w-4 h-4 text-primary" />
                  </button>
                  <p className="text-xs text-muted-foreground">
                    Una vez que ya estás suscrito, puedes:
                  </p>
                  <ul className="list-disc list-inside text-xs text-muted-foreground">
                    <li>Revisar el contenido semanal</li>
                    <li>Personalizar tu caja</li>
                    <li>
                      Complementarla el pedido para que vaya en el mismo envío
                    </li>
                  </ul>
                  <p className="text-xs text-muted-foreground">
                    Además de eso, siempre puedes pausar tu suscripción,
                    saltarte una caja o cancelar cuando quieras.
                  </p>
                  <p className="text-xs text-muted-foreground">
                    Nuestra suscripción te hace la vida más fácil, ahorrando
                    plata, tiempo y ayudando al planeta 🌎💚.
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="py-4 relative">
              {resumeCheckoutIsFetching && (
                <div className="absolute inset-0 bg-white/80 z-10 flex items-center justify-center">
                  <div>
                    <Loading message="🔍 Actualizando tu información..." />
                  </div>
                </div>
              )}
              <Summary
                {...resumeCheckoutResponse!.data}
                isLoggedIn={userIsLoggedIn}
                onChangeStep={(step) => handleStepChange(step)}
              />
            </div>
          </motion.div>
        );
      case "SUBSCRIPTION":
        return (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            transition={pageTransition}
          >
            <h4 className="text-lg font-semibold">Tipo de suscripción</h4>
            <p className="text-sm">
              Selecciona el tipo de suscripción que deseas recibir.
            </p>
            <div className="py-4">
              <Subscriptions
                onGoBack={() => handleStepChange("SUMMARY")}
                initialSelectedSubscription={resumeCheckoutData.subscription_id}
              />
            </div>
          </motion.div>
        );
      case "DELIVERY_DATE":
        return (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            transition={pageTransition}
          >
            <h4 className="text-lg font-semibold">Fecha primer envío</h4>
            <p className="text-sm">
              Selecciona la fecha en que deseas recibir tu primer envío.
            </p>
            <div className="py-4">
              <DeliveryDate
                onGoBack={() => handleStepChange("SUMMARY")}
                frecuencies={resumeCheckoutData.frecuencies}
                initialDeliveryDate={
                  resumeCheckoutData.form_shipping_first_delivery_date
                }
              />
            </div>
          </motion.div>
        );
      case "FRECUENCY":
        return (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            transition={pageTransition}
          >
            <h4 className="text-lg font-semibold">Frecuencia de envío</h4>
            <p className="text-sm">
              ¿Con que frecuencia deseas recibir tu caja?
            </p>
            <div className="py-4">
              <Frecuency
                onGoBack={() => handleStepChange("SUMMARY")}
                initialFrecuency={
                  resumeCheckoutData.shipping_frecuency as
                    | "Semanal"
                    | "Quincenal"
                }
              />
            </div>
          </motion.div>
        );
      case "SHIPPING_METHOD":
        return (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            transition={pageTransition}
          >
            <h4 className="text-lg font-semibold">Método de entrega</h4>
            <p className="text-sm">¿Como quieres recibir tus cajas?</p>
            <div className="py-4">
              <ShippingMethod
                onGoBack={() => handleStepChange("SUMMARY")}
                initialData={{
                  shipping_type:
                    resumeCheckoutData.shipping_type as ShippingType,
                  pickup_district_name: resumeCheckoutData.pickup_district_name,
                  pickup_name: resumeCheckoutData.pickup_name,
                  delivery_district_name:
                    resumeCheckoutData.delivery_district_name,
                  delivery_address: resumeCheckoutData.delivery_address,
                  delivery_address_number:
                    resumeCheckoutData.delivery_address_number,
                  additional_data: resumeCheckoutData.additional_data,
                }}
              />
            </div>
          </motion.div>
        );
    }
  };

  return (
    <div className="relative">
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={step}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageVariants}
          transition={pageTransition}
          className="w-full"
        >
          {renderStep()}
          {hasError && (
            <p className="text-red-500 text-center font-semibold text-sm">
              Hubo un error al realizar tu suscripción. Por favor intenta
              nuevamente.
            </p>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default SubscriptionIncomplete;
