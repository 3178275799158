import { Link } from "@tanstack/react-router";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Confetti from "react-confetti";
import meadowImage from "@/assets/meadow.png";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { personalDataMutation } from "@/services/queries";

const successFormSchema = z.object({
  user_notes: z.string().optional(),
});

type SuccessFormSchema = z.infer<typeof successFormSchema>;

const RegisterSuccess = () => {
  const saveUserData = personalDataMutation();

  const form = useForm<SuccessFormSchema>({
    resolver: zodResolver(successFormSchema),
  });

  const handleSubmit = (values: SuccessFormSchema) => {
    saveUserData.mutate(values);
  };

  const renderForm = () => {
    if (saveUserData.isSuccess) {
      return (
        <>
          <p className="text-base text-center">
            ¡Tendremos en cuenta tus preferencias al momento de tus envíos!
          </p>
        </>
      );
    }
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <FormField
            control={form.control}
            name="user_notes"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel htmlFor="name">Alergias o restricciones</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Indicanos si tienes alguna alergia o restricción alimentaria"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={saveUserData.isPending}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <div className="flex pt-4 justify-end">
            <Button
              variant="outline"
              disabled={saveUserData.isPending}
              isLoading={saveUserData.isPending}
            >
              {saveUserData.isPending ? "Guardando" : "Guardar"}
            </Button>
          </div>
        </form>
      </Form>
    );
  };

  return (
    <>
      <div className="mx-auto max-w-[400px] w-full mb-8 relative z-10">
        <Card className="pt-6">
          <CardContent className="grid gap-4">
            <p className="text-primary text-center font-medium text-2xl">
              ¡Tu suscripción está lista!
            </p>
            <div className="w-44 h-44 rounded-full mx-auto overflow-hidden">
              <img
                src={meadowImage}
                alt="Imagen de vegetales y frutas en una pradera soleada"
              />
            </div>
            <p className="text-base text-center">
              En tu cuenta puedes ajustar el tamaño de la caja la frecuencia de
              envío, el contenido y mucho más.
            </p>
            <Button className="w-full">
              <Link to="/user/home">Ir a mi cuenta</Link>
            </Button>
          </CardContent>
          <Separator className="mb-4" />
          <div className="p-6 pt-0">{renderForm()}</div>
        </Card>
      </div>
      <Confetti colors={["#7b7e25", "#ee7632"]} numberOfPieces={50} />
    </>
  );
};

export default RegisterSuccess;
