import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import vegetablesImage from "@/assets/home/vegetables.png";
import { cuttoff_time } from "@/config/const";

const AccordionSection = ({
  value,
  title,
  children,
}: {
  value: string;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <AccordionItem value={value}>
      <AccordionTrigger className="text-left text-base">
        {title}
      </AccordionTrigger>
      <AccordionContent>{children}</AccordionContent>
    </AccordionItem>
  );
};

const FAQ = () => {
  return (
    <div
      className="pb-0 relative z-10 -translate-y-20 lg:flex lg:flex-row lg:-translate-y-0 max-w-7xl mx-auto"
      id="faq-section"
    >
      <div className="lg:-translate-y-28 lg:h-[400px]">
        <div className="w-1/2 mx-auto">
          <img src={vegetablesImage} alt="Verduras imperfectas" />
        </div>
      </div>
      <div className="p-6 lg:w-3/4 lg:pr-10 lg:pt-20">
        <h4 className="text-center text-2xl lg:text-3xl mb-2 font-bold">
          PREGUNTAS FRECUENTES
        </h4>
        <div>
          <Accordion type="single" collapsible className="w-full">
            <AccordionSection value="item-1" title="¿Qué es Maifud?">
              Maifud es un mercado online de frutas y verduras, además de otros
              productos de despensa. Ofrecemos de manera online y al detalle
              alimentos “imperfectos” rechazados por el comercio tradicional por
              no cumplir las exigencias de mercado en cuanto a tamaño, color,
              forma o grado de madurez, a pesar de encontrarse en perfectas
              condiciones para ser consumidos.
            </AccordionSection>

            <AccordionSection
              value="item-2"
              title="¿En qué estado se encuentran los alimentos que ofrecen?"
            >
              Todos nuestros productos son frescos, cosechados durante la misma
              semana o semana anterior del despacho y que se encuentran en
              perfectas condiciones para ser consumidos en términos
              nutricionales y de inocuidad.
            </AccordionSection>

            <AccordionSection
              value="item-3"
              title="¿Cúal es el origen de sus productos?"
            >
              Nos contactamos con pequeños, medianos y grandes agricultores y
              distribuidores locales de la región metropolitana, IV, V y VI.
              Tratamos de ofrecer productos limpios cultivados de forma orgánica
              o agroecológica, sin embargo, también ofrecemos productos de
              agricultura convencional cuando se trata de alimentos que han sido
              descartados. En la información adicional de cada producto puedes
              ver la forma que fue cultivado.
            </AccordionSection>

            <AccordionSection
              value="item-4"
              title="¿Qué son las Cajas Imperfectas?"
            >
              <p className="mb-2">
                Son cajas de frutas y verduras convenientes: tienen un 30% de
                descuento respecto al mercado tradicional.
              </p>
              <p className="mb-2">
                Las frutas y verduras están en perfecto estado, pero que han
                sido descartadas en la cosecha por no cumplir los estrictos
                estándares estéticos que exige el mercado. Las puedes adquirir a
                granel o en modalidad de Suscripción a precios aún más
                convenientes.
              </p>
              <p className="mb-2">
                En la Caja podrás encontrar desde <strong>15 variedades</strong>{" "}
                de frutas y verduras de temporada, dependiendo de lo que
                logramos rescatar de productores asociados.
              </p>
            </AccordionSection>

            <AccordionSection
              value="item-5"
              title="¿Cómo funciona el despacho?"
            >
              <p className="mb-2">
                Repartimos durante la semana de <strong>lunes a sábado</strong>.
              </p>
              <p className="mb-2">
                De <strong>domingo a viernes</strong> si pides antes de las{" "}
                <strong>{cuttoff_time} hrs</strong> podrás recibir al{" "}
                <strong>día siguiente</strong>, y siempre y cuando ese día no se
                exceda nuestra capacidad logística (avisaríamos oportunamente).
                En ese caso quedaría la entrega para el día subsiguiente.
              </p>
              <p className="mb-2">
                Los pedidos hechos desde el viernes posterior a las{" "}
                <strong>{cuttoff_time} hrs</strong> hasta el domingo a las{" "}
                <strong>19:00 hrs</strong> se entregarán el día lunes.
              </p>
            </AccordionSection>

            <AccordionSection
              value="item-6"
              title="¿Cómo funcionan los puntos de retiro?"
            >
              <p className="mb-2">
                El retiro tiene un costo de <strong>$1.000</strong> a contar de
                Marzo 2024, con el fin de poder sostener la operación de los
                despachos a estos puntos.
              </p>
              <p className="mb-2">
                Para el caso del punto de retiro en{" "}
                <strong>Apoquindo 6451, Las Condes</strong>, se pueden retirar
                el mismo día que se pide siempre y cuando sea desde las{" "}
                <strong>14:00 hasta las 17:00 hrs</strong> previo coordinación
                por whatsapp de soporte.
              </p>
              <p className="mb-2">
                Para el resto de puntos de retiro, si se pide hasta las{" "}
                <strong>{cuttoff_time} hrs</strong> de un día se puede retirar al día
                siguiente en los horarios establecidos (sólo retiros de lunes a
                viernes), los sábados no hacemos retiros, por lo que los pedidos
                puestos posterior a las {cuttoff_time} hrs del jueves, quedan para el día
                lunes.
              </p>
              <p className="mb-2">
                Una vez hecho tu pedido{" "}
                <strong>
                  tienes que esperar la confirmación por correo para que puedas
                  retirarlo
                </strong>
                . Debes retirarlo el mismo día que te indicamos.
              </p>
              <p className="mb-2">
                <strong>Política de retiro:</strong> Los retiros se deben
                realizar el mismo día que se indica en el correo de confirmación
                para retiro. Si no se cumple con esto, no nos hacemos
                responsables por deterioros que pueda sufrir el pedido en días
                posteriores. Somos una empresa que combate el desperdicio de
                alimentos y lo que menos queremos es que dejen olvidados sus
                pedidos en nuestras oficinas y que finalmente se pierdan.
              </p>
            </AccordionSection>
            <AccordionSection
              value="item-7"
              title="¿Qué valor tiene el despacho?"
            >
              <p className="mb-2">
                El valor de despacho varía por comuna pero va de{" "}
                <strong>$3.000 a $3.900</strong>. Puedes ver nuestras zonas de{" "}
                <a
                  href="https://tienda.maifud.cl/despacho"
                  className="text-primary underline"
                >
                  reparto acá
                </a>
                .
              </p>
            </AccordionSection>
            <AccordionSection value="item-8" title="¿A qué comunas despachan?">
              <p className="mb-2">
                Dentro de la <strong>Región Metropolitana</strong> y la{" "}
                <strong>V Región</strong> en las comunas que encontrarás en{" "}
                <a
                  href="https://tienda.maifud.cl/despacho"
                  className="text-primary underline"
                >
                  este link
                </a>
              </p>
            </AccordionSection>
            <AccordionSection
              value="item-9"
              title="¿Qué pasa si nadie puede recibir el día que me despachan?"
            >
              <p className="mb-2">
                El día antes del despacho nosotros enviamos correo para que el
                cliente tome las precauciones de poder recibir. En caso que no
                pueda recibir ese día, nos tiene que avisar ese mismo día para
                poder reagendar su entrega. Si el cliente no nos dice nada y el
                pedido se despacha, y no hay nadie el día de la entrega, el
                pedido vuelve a nuestras bodegas y el despacho se pierde,
                teniendo el cliente que pagar un nuevo despacho.
              </p>
            </AccordionSection>
          </Accordion>
        </div>
        <p className="text-center mt-10">
          ¿Tienes alguna pregunta que no esté aquí?{" "}
          <a
            href="https://wa.me/+56971295665"
            className="text-primary underline"
          >
            Contáctanos
          </a>
        </p>
      </div>
    </div>
  );
};

export default FAQ;
