import { Loading, PageLayout, SidebarNav } from "@/components";
import { Separator } from "@/components/ui/separator";
import { Outlet, Navigate } from "@tanstack/react-router";
import { userHomeQuery } from "@/services/queries";

export const sidebarNavItems = [
  {
    title: "Inicio",
    href: "/user/home",
    emoji: "🏠",
  },
  ,
  {
    title: "Suscripción",
    href: "/user/subscription",
    emoji: "📦",
  },
  {
    title: "Datos personales",
    href: "/user/account",
    emoji: "👤",
  },
  {
    title: "Pedidos",
    href: "/user/orders",
    emoji: "📦",
  },
  {
    title: "Métodos de pago",
    href: "/user/payment-methods",
    emoji: "💳",
  },
  {
    title: "Dirección envío / retiro",
    href: "/user/shipping/edit",
    emoji: "🌎",
  },
  {
    title: "Cupones",
    href: "/user/discounts",
    emoji: "🎟️",
  },
  {
    title: "Comparte y gana",
    href: "/user/referral",
    emoji: "🧡",
  },
  {
    title: "Cerrar sesión",
    href: "/logout",
    emoji: "🔒",
  },
];

const User = () => {
  const { data: response, isLoading } = userHomeQuery();
  const data = response?.data;

  const userHasPendingSubscription = data?.show_resume_button;

  const renderUserName = (name?: string) => {
    // Only show the first name.
    return name ? `Hola ${name.split(" ")[0]}` : "Hola";
  };

  if (isLoading) {
    return (
      <PageLayout>
        <div className="pt-6">
          <Loading message="Buscando tu información..." />
        </div>
      </PageLayout>
    );
  }

  // Redirecto to resume checkout if user has a pending subscription
  if (userHasPendingSubscription) {
    return (
      <Navigate
        to="/rcheckout"
        search={{
          uid: data?.username,
        }}
      />
    );
  }

  return (
    <PageLayout>
      <div className="pt-6">
        <div className="hidden lg:block">
          <div className="space-y-0.5">
            <h2 className="text-2xl font-bold tracking-tight flex space-x-2 items-center justify-between">
              <div>{renderUserName(data?.user_name)}</div>
            </h2>
            <p className="text-muted-foreground">
              Maneja tu cuenta y tus preferencias.
            </p>
          </div>

          <Separator className="mt-6 mb-4" />
        </div>
        <div className="flex flex-col space-b-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="lg:w-1/5">
            {<SidebarNav items={sidebarNavItems} />}
          </aside>
          <div className="flex-1 lg:max-w-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default User;
