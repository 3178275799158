import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useState, useEffect } from "react";
import * as z from "zod";
import { TableItem, CustomLink } from "@/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { subscriptionMutation } from "@/services/queries";

type SubscriptionEditFormProps = {
  available_days_data: any;
  selected_subscription_size_humanized: string;
  selected_shipping_frecuency_humanized: string;
  selected_next_weekday_humanized: string;
  available_subscription_names: string[];
  edit_is_disabled: boolean;
};

const subscriptionEditSchema = z.object({
  selected_subscription_size_humanized: z.string(),
  selected_shipping_frecuency_humanized: z.string(),
  selected_next_weekday_humanized: z.string(),
  selected_next_weekday: z.string().optional(),
});

export type SubscriptionEditSchema = z.infer<typeof subscriptionEditSchema>;

const SubscriptionEditForm = ({
  selected_subscription_size_humanized,
  selected_shipping_frecuency_humanized,
  selected_next_weekday_humanized,
  available_days_data,
  available_subscription_names,
  edit_is_disabled,
}: SubscriptionEditFormProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const mutation = subscriptionMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      setShowConfirmation(false);
    }
  }, [mutation.isSuccess]);

  const handleSubmit = (values: SubscriptionEditSchema) => {
    // Eg: Get "Jueves" from the string "Jueves 22 de febrero"
    const selected_next_weekday =
      values.selected_next_weekday_humanized.split(" ")[0];
    mutation.mutate({ ...values, selected_next_weekday });
  };

  const renderConfirmationAlert = () => {
    const {
      selected_subscription_size_humanized: subscription,
      selected_shipping_frecuency_humanized: frecuency,
      selected_next_weekday_humanized: nextDeliveryDay,
    } = form.getValues();
    const nextDeliveryDOW = nextDeliveryDay
      ? nextDeliveryDay.split(" ")[0]
      : null;

    return (
      <AlertDialog open={showConfirmation}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>¿Confirmas estos cambios?</AlertDialogTitle>
            <AlertDialogDescription>
              <p className="mb-2">
                Tu suscripción quedaría de la siguiente forma:
              </p>
              <TableItem title="Tipo" content={subscription} />
              <TableItem title="Frecuencia" content={frecuency} />
              <TableItem title="Siguiente entrega" content={nextDeliveryDay} />
              <p className="py-2 text-center">
                Tus cajas comenzarán a llegar los días {nextDeliveryDOW}
              </p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setShowConfirmation(false)}>
              Cancelar
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={form.handleSubmit(handleSubmit)}
              disabled={mutation.isPending}
            >
              Confirmar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  const availableDaysOnFrencuency = Object.values(
    available_days_data.data[
      selected_shipping_frecuency_humanized as "Semanal" | "Quincenal"
    ],
  ).map((d) => (d as string).toLowerCase());

  const form = useForm<SubscriptionEditSchema>({
    resolver: zodResolver(subscriptionEditSchema),
    defaultValues: {
      selected_subscription_size_humanized,
      selected_shipping_frecuency_humanized,
      selected_next_weekday_humanized,
    },
  });

  const { dirtyFields, touchedFields } = form.formState;
  const isUntouched =
    Object.keys(dirtyFields).length === 0 &&
    Object.keys(touchedFields).length === 0;

  return (
    <div className="grid grid-cols-1 gap-6 items-start">
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirmation(true);
          }}
          className="space-y-6"
        >
          <FormField
            control={form.control}
            name="selected_subscription_size_humanized"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel htmlFor="name">Tipo de caja</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      {available_subscription_names.map((s) => (
                        <FormItem
                          className="flex items-center space-x-3 space-y-0"
                          key={s}
                        >
                          <FormControl>
                            <RadioGroupItem value={s} />
                          </FormControl>
                          <FormLabel className="font-normal">{s}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="selected_shipping_frecuency_humanized"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel htmlFor="name">Frecuencia</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={"Semanal"} />
                        </FormControl>
                        <FormLabel className="font-normal">Semanal</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={"Quincenal"} />
                        </FormControl>
                        <FormLabel className="font-normal">Quincenal</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="selected_next_weekday_humanized"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel htmlFor="name">Siguiente entrega</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={
                      field.value ? field.value.toLowerCase() : field.value
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Fechas de envío disponibles" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {availableDaysOnFrencuency.map((day) => {
                        return (
                          <SelectItem key={day} value={day}>
                            {day}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {edit_is_disabled && (
            <FormMessage className="text-right">
              ⚠️ Tu siguiente orden ya fue creada. Podrás editar tu suscripción
              de nuevo después de que te propongamos tu caja (Lunes AM).
            </FormMessage>
          )}
          <div className="flex flex-col lg:flex-row lg:justify-end gap-2">
            <Button variant="link">
              <CustomLink to="/user/home">Volver</CustomLink>
            </Button>
            <Button
              className="w-full lg:w-fit"
              type="submit"
              disabled={mutation.isPending || isUntouched || edit_is_disabled}
              isLoading={mutation.isPending}
            >
              {mutation.isPending ? "Guardando" : "Guardar"}
            </Button>
          </div>
          {mutation.isError && (
            <FormMessage className="text-right">
              {mutation.error.message}
            </FormMessage>
          )}
          {mutation.isSuccess && (
            <FormMessage className="text-right text-primary">
              Tus cambios han sido guardados correctamente
            </FormMessage>
          )}
          {renderConfirmationAlert()}
        </form>
      </Form>
    </div>
  );
};

export default SubscriptionEditForm;
